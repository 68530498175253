import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import jquery from "jquery" 
import "./jquery-validation" 

Rails.start()
ActiveStorage.start()

// jquery 定義，要加這兩行才可以全域使用
window.jQuery = jquery
window.$ = jquery 