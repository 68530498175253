
String.prototype.toNumber = function() {
  var value = parseFloat(this);
  return isNaN(value) ? undefined : value;
};
String.prototype.decodeURIComponent = function() {
  return decodeURIComponent(this.replace(/\+/g, ' ')); 
} 

String.prototype.removeUrlHash = function() {
  if (this.indexOf('#') != -1) {
    var hashLeft = this.split('#')[0];
    var hashRight = this.split('#')[1];
    var returnUrl = hashLeft;
    if (hashRight && hashRight.indexOf('?') != -1) {
      returnUrl = returnUrl + "?" + hashRight.split('?')[1];
    }
    return returnUrl;
  } else {
    return this;
  }
} 
String.prototype.toYYYYMMDD = function(){
  let nowDateTime = new Date(this);
  let nowDate = null;
  if (nowDateTime) {
    nowDate = nowDateTime.getFullYear()
      + "/"
      + ((nowDateTime.getMonth()+1).toString().length == 1 ? "0":"" ) + (nowDateTime.getMonth()+1)
      + "/"
      + ((nowDateTime.getDate()).toString().length == 1 ? "0":"" ) + nowDateTime.getDate()
  }
  return nowDate;  
}
String.prototype.updateQueryStringParameter = function(key, value) {
  var re = new RegExp("([?|&])" + key + "=.*?(&|$)", "i");
  separator = this.indexOf('?') !== -1 ? "&" : "?";
  if (this.match(re)) {
    return this.replace(re, '$1' + key + "=" + value + '$2');
  }
  else {
    return this + separator + key + "=" + value;
  }
}

String.prototype.getQueryString = function(name)
{
    var vars = [], hash;
    var hashes = this.slice(this.indexOf('?') + 1).split('&');
    for(var i = 0; i < hashes.length; i++)
    {
        hash = hashes[i].split('=');
        vars.push(hash[0]);
        vars[hash[0]] = hash[1];
    }
    return ( vars[name] != undefined ) ? vars[name] : "";
}

Array.prototype.findBy = function(byWhatKey,byWhatValue)
{
  var firstTarget = null;
  for (var i = 0; i < this.length; i++) {
    if(this[i][byWhatKey] == byWhatValue && !firstTarget ){
      firstTarget = this[i];
    }
  };
  return firstTarget;
}

Array.prototype.findListBy = function(byWhatKey,byWhatValue)
{
  var list = [];
  if(byWhatKey && byWhatValue){
    for (var i = 0; i < this.length; i++) {
      if(typeof byWhatValue == "object" && (byWhatValue.indexOf(this[i][byWhatKey]) != -1 || byWhatValue.indexOf(this[i][byWhatKey].toString()) != -1)){
        list.push(this[i]);
      } else if(this[i][byWhatKey] == byWhatValue){
        list.push(this[i]);
      }
    };
  } 
  return list;
}

Array.prototype.sum = function(whatSum)
{
  var sum = 0;
  for (var i = 0; i < this.length; i++) {
    sum = sum + parseInt(this[i][whatSum],10)
  };
  return sum;
}

String.prototype.toJson = function()
{ 
  return JSON.parse(this);
}

Array.prototype.uniq = function(keyName)
{ 
  var uniqArray = [];
  var keys = {};
  for (var i = 0; i < this.length; i++) {
    if(!keys[this[i][keyName]]){
      uniqArray.push(this[i]);
      keys[this[i][keyName]] = true;
    }
  };
  return uniqArray;
}
 
Array.prototype.unique = function()
{  
  return this.filter(function(value, index, self){ return self.indexOf(value) === index; });
}

Array.prototype.groupBy = function(key)
{  
  return this.reduce(function(result, current) {
      result[current[key]] = result[current[key]] || [];
      result[current[key]].push(current);
      return result;
  }, {});
}
 
Array.prototype.intersection = function(anotherArray)
{  
  return this.filter(value => anotherArray.includes(value));
}

Array.prototype.union = function(anotherArray)
{  
  return [...new Set([...this, ...anotherArray])]
}
Array.prototype.first = function(){
  return this[0];
}
Array.prototype.last = function(){
  return this[this.length - 1];
}
var Utility = { 
  createNumberArray(from,to){
    let numbers = [];
    for (var i = from; i <= to; i++) {
      numbers.push(i);
    }
    return numbers;
  },  
  whiteList(obj, props) {
    const propNames = props.split(",");
    return propNames.reduce((result, propName) => {
      if (propName in obj) {
        result[propName] = obj[propName];
      }
      return result;
    }, {});
  }, 
  invert(json){
    var ret = {};
    for(var key in json){
      ret[json[key]] = key;
    }
    return ret;
  },
  nullToEmptyString: function(data){
    result = {...data};
    $.each(data, function(k,v){
      if( result[k] && result[k]["name"] && result[k]["record_type"] && result[k]["body"] == null && result[k]["id"] == null ){
        result[k] = "";  
      } else {
        result[k] = ( ( v == null || v == undefined ) ? "": v ); 
      } 
    });
    return result;
  },
  jsonToArray: function(json){ 
    return Object.keys(json).map((key) => {
      var object = {};
      object["key"] = key;
      object["value"] = json[key]; 
      return object;
    }); 
  },
  calculateTax: function(taxIncluded, orderTotalAmount, taxRate, canNotBeInvoicedWithAlreadyPayment){
    var tax = null; 
    canNotBeInvoicedWithAlreadyPayment = canNotBeInvoicedWithAlreadyPayment ? true : false;

    if( taxIncluded == true ){
      tax =  Math.round((orderTotalAmount - (orderTotalAmount / (1 + taxRate/100)))*100)/100;  
    }

    if ( taxIncluded == false ) {
      tax = Math.round(orderTotalAmount * (taxRate / 100));
    }

    if (canNotBeInvoicedWithAlreadyPayment) {
      tax = 0;
    }

    $(document).trigger( "refreshTaxEvent", [taxIncluded, orderTotalAmount, taxRate, tax, canNotBeInvoicedWithAlreadyPayment] );
    return tax;
  },
  getYYYYMMDDHHMMSS: function(){ 
    var nowDateTime = new Date();
    var nowDate = null;
    if (nowDateTime) {
      nowDate = nowDateTime.getFullYear()
          + "/"
          + ((nowDateTime.getMonth()+1).toString().length == 1 ? "0":"" ) + (nowDateTime.getMonth()+1)
          + "/"
          + ((nowDateTime.getDate()).toString().length == 1 ? "0":"" ) + nowDateTime.getDate()
          + " "
          + ((nowDateTime.getHours()).toString().length == 1 ? "0":"" ) + nowDateTime.getHours()
          + ":"
          + ((nowDateTime.getMinutes()).toString().length == 1 ? "0":"" ) + nowDateTime.getMinutes()
          + ":"
          + ((nowDateTime.getSeconds()).toString().length == 1 ? "0":"" ) + nowDateTime.getSeconds();
    } 
    return nowDate; 
  }, 
  getYYYYMMDD: function(dateTime){ 
    var nowDateTime = dateTime;
    var nowDate = null;
    if (nowDateTime) {
      nowDate = nowDateTime.getFullYear()
        + "/"
        + ((nowDateTime.getMonth()+1).toString().length == 1 ? "0":"" ) + (nowDateTime.getMonth()+1)
        + "/"
        + ((nowDateTime.getDate()).toString().length == 1 ? "0":"" ) + nowDateTime.getDate()
    }
    return nowDate; 
  },  
  try: function(json, key){ 
    var returnValue = null;
    if (json) {
      returnValue = json[key];
    }
    return returnValue;
  }, 
  getDomain:function(){
    var locationHrefArray = location.href.split('/');
    var currentDomain = locationHrefArray[0] + "//" + locationHrefArray[2];
    return currentDomain;
  },
  getCurrentPrice:function(thisProductModelId, thisProductId, thisProductPrice, memberLevelId, productModels, productPrices){  
    var tempPrice = null;

    if (!memberLevelId) {
      if (thisProductModelId) {
        //沒有 memberLevelId , 有 thisProductModelId 則 從 productModels 裡面抓價格
        $.each(productModels,function(i,v){
          if (v["id"] == thisProductModelId && v["product_id"] == thisProductId) {
            tempPrice = v["price"]*1;
          }
        });        
      }          
    } else {
      if (!thisProductModelId) {
        //有 memberLevelId , 沒有 thisProductModelId 則 從 productPrices 裡面抓價格
        $.each(productPrices,function(i,v){
          if ( !v["product_model_id"] && v["member_level_id"] == memberLevelId && v["product_id"] == thisProductId) {
            tempPrice = v["price"]*1;
          }
        });         
      } else {
        //有 memberLevelId , 有 thisProductModelId 則 從 productPrices 裡面抓價格
        $.each(productPrices,function(i,v){
          if (v["product_model_id"] == thisProductModelId && v["member_level_id"] == memberLevelId && v["product_id"] == thisProductId) {
            tempPrice = v["price"]*1;
          }
        }); 
        if (!tempPrice) {
          //如果沒有 抓到價格 , 那就當作 沒有 thisProductModelId 的情況下 再去productPrices 裡面抓價格
          $.each(productPrices,function(i,v){
            if ( !v["product_model_id"] && v["member_level_id"] == memberLevelId && v["product_id"] == thisProductId) {
              tempPrice = v["price"]*1;
            }
          }); 
        }         
      }       
    } 

    //前面都沒有抓到價格的情況 則 直接抓 product 本身設定的 price
    if (!tempPrice) {
      tempPrice = thisProductPrice;
    } 
    return tempPrice; 
  },
  formatNumber:function (f)
  {
    return Math.round(f*100)/100;
  },
  addCommas:function (nStr) {
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }
    return x1 + x2;
  },
  showFormatedDateTime:function(date) {  
    var formatDate = "";
    var nowTime = new Date(date);
    if(date) {
      formatDate = nowTime.getFullYear() 
                  + "/" 
                  + ((nowTime.getMonth()+1).toString().length == 1 ? "0":"" ) + (nowTime.getMonth()+1) 
                  + "/" 
                  + ((nowTime.getDate()).toString().length == 1 ? "0":"" ) + nowTime.getDate()
                  + " "
                  + ((nowTime.getHours()).toString().length == 1 ? "0":"" ) + nowTime.getHours()
                  + ":"
                  + ((nowTime.getMinutes()).toString().length == 1 ? "0":"" ) + nowTime.getMinutes()
    }  
    return formatDate;
  }, 
  showFormatedDate:function(date) {  
    var formatDate = "";
    var nowTime = new Date(date);
    if(date) {
      formatDate = nowTime.getFullYear() 
                  + "/" 
                  + ((nowTime.getMonth()+1).toString().length == 1 ? "0":"" ) + (nowTime.getMonth()+1) 
                  + "/" 
                  + ((nowTime.getDate()).toString().length == 1 ? "0":"" ) + nowTime.getDate(); 
    }  
    return formatDate;
  },  
  generateID:function(hasDash) {  
    var S4 = function() {
      return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    } 
    var guid = function() {
      if(hasDash){ 
        return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
      } else { 
        return (S4()+S4()+S4()+S4()+S4()+S4()+S4()+S4());
      }
    } 
    return guid();
  },
  escapeString: function(originString) {
    return originString.replace("'","\\'").replace("\"","\\\"");
  },
  isBackend: function () {    
    return !(window.location.pathname.indexOf("/backend/") == -1 && window.location.pathname.indexOf("/backend") == -1)
  },
  checkFacebookAuthorization: function() {    
    var dont_bother_me = Cookies.get('dont_bother_me_about_reauthorize');    
    FB.getLoginStatus(function(response) {         
      if (response.status === 'connected') {        
        var accessToken = response.authResponse.accessToken;

        FB.api("/me", {accessToken: accessToken, fields: "installed,permissions"}, function(meResponse) {          
          var showModel = false;            
          if(meResponse.error) {            
            if(meResponse.error.code && meResponse.error.code === 190) {
              showModel = true;
            } else {
              alert(meResponse.error.message);
              return;
            }            
          }        
          if(meResponse.permissions && meResponse.permissions.data) {            
            var permissionGroup = meResponse.permissions.data.find(function(item) { return item.permission == "groups_access_member_info"});   
            if(!permissionGroup || permissionGroup.status != "granted") {
              showModel = true;
            }            
          } else {
            showModel = true;
          } 

          if(showModel && !dont_bother_me) {
            $('#reauthorizeModalAlert').modal('show');  
          }
        });      
      } else if (response.status === 'not_authorized') {
        if(!dont_bother_me) {
          $('#reauthorizeModalAlert').modal('show');
        }
      }
      // response.status == unknown 有可能會因被chrome封鎖第三方cookie擋掉而出現
    });
  }, 
  checkFacebookBotAuthorization: function() {      
    FB.getLoginStatus(function(response) {        
      if (response.status === 'connected') {        
        var accessToken = response.authResponse.accessToken;
        FB.api("/me", {accessToken: accessToken, fields: "installed,permissions"}, function(meResponse) { 
          var showAuthorizationButton = true;      
            if(meResponse.permissions && meResponse.permissions.data) {            
            var pagesMessaging = meResponse.permissions.data.find(function(item) { return item.permission == "pages_messaging"});
            if( (pagesMessaging && pagesMessaging.status == "granted") ) {
              showAuthorizationButton = false;  
            }
          }    
          if(showAuthorizationButton) {
            $(".form-group").has(".get-facebook-permission").removeClass("hide"); 
            $(".form-group").has(".select-current-facebook-page").addClass("hide"); 
          } else {
            $(".form-group").has(".get-facebook-permission").addClass("hide"); 
            $(".form-group").has(".select-current-facebook-page").removeClass("hide"); 
          } 
        });      
      } 
    });
  },
  swap: function(json){
    var ret = {};
    for(var key in json){
      ret[json[key]] = key;
    }
    return ret;
  }
}
 
window.Utility = Utility;
